import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";

import Topbar from '../components/topbar'
import Footer from '../components/footer'
import { APP_URI } from '../configs';
import {Link} from 'gatsby'

import ilustration from '../images/ilustration.jpg'

import banner from '../images/troop-cup-banner.png'

import setup from '../images/setup-icon.svg'
import clothes from '../images/clothes-icon.svg'
import purchase from '../images/purchase-icon.svg'
import kit from '../images/kit-icon.svg'

import setupYellow from '../images/setup-yellow.svg'
import clothesYellow from '../images/clothes-yellow.svg'
import purchaseYellow from '../images/purchase-yellow.svg'
import dealYellow from '../images/deal-icon-yellow.svg'

import cod from '../images/cod-screen.png'
import lol from '../images/lol-screen.png'
import cs from '../images/cs-screen.png'
import fortnite from '../images/fortnite-screen.png'

import team from '../images/team-icon.svg'
import players from '../images/players-icon.svg'

import clock from '../images/clock-icon.svg'
import calendar from '../images/calendar-icon.svg'

import '../scss/troop-cup.scss'

export default class HowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    new WOW.WOW({
        live: false,
        offset: 500
    }).init();
  }

  scrollToReference(ref) {
    const node = findDOMNode(ref.current);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  render(){
    return(
    <div className="troop-cup">
    <div className="content troop-cup-content">
      <Topbar handlerNavigation={(ref) => this.scrollToReference(ref)} pageReferences={this.references}/>
      <Helmet>

      </Helmet>

      <section ref={this.references.hero} className="hero--section">
        <div className="wrapper">
          <div className="flex--container">
            <h1 className="hero--title">Sumate a la Troop CUP 21</h1>
            <p className="hero--text">
            Conectá y conocé a otros jugadores, creá equipos competitivos, conocé nuevos
compañeros y competí para convertirte en el gamer que siempre soñaste.
            </p>
            <Link to="/tournaments" className="button--global yellow">Participá</Link>
          </div>
        </div>
      </section>

      <section ref={this.references.hero} className="invite--section">
        <div className="wrapper">
            <h2 className="invite--title wow fadeIn" data-wow-offset="200">
            Participá por increíbles premios
            </h2>
            <img src={banner}/>
          </div>
      </section>

      <section ref={this.references.features} className="winners--section">
        <div className="wrapper">
        <div className="winners--list wow fadeIn" data-wow-offset="200">
            <div className="winners-prize prize-3 ">
                <h3 className="prize--title">
                  Top 2
                </h3>
                <ol className="item--list">
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={setup} />
                    </div>
                    <p className="item--text">
                    Setup gamer para cada jugador. Incluye: periféricos, monitor, y una PC de última generación.
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={clothes} />
                    </div>
                    <p className="item--text">
                    Crear camisetas propias y merchandising para el equipo.
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={purchase} />
                    </div>
                    <p className="item--text">
                    Órdenes de compra en tiendas de software y hardware para gaming.
                    </p>
                  </li>
                </ol>
            </div>
            <div className="winners-prize prize-1">
            <h3 className="prize--title gold">
                  Top 1
                </h3>
                <ol className="item--list">
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={dealYellow} />
                    </div>
                    <p className="item--text">
                    Conectar con sponsors para que inviertan en el equipo.
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={setupYellow} />
                    </div>
                    <p className="item--text">
                    Setup gamer para cada jugador. Incluye: periféricos, monitor, y una PC de última generación.
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={clothesYellow} />
                    </div>
                    <p className="item--text">
                    Crear camisetas propias y merchandising para el equipo.
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={purchaseYellow} />
                    </div>
                    <p className="item--text">
                    Órdenes de compra en tiendas de software y hardware para gaming.
                    </p>
                  </li>
                </ol>
            </div>
            <div className="winners-prize prize-2">
            <h3 className="prize--title">
                  Top 3
                </h3>
                <ol className="item--list">
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={kit} />
                    </div>
                    <p className="item--text">
                    Kit completo de periféricos (incluye monitores competitivos).
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={clothes} />
                    </div>
                    <p className="item--text">
                    Crear camisetas propias y merchandising para el equipo.
                    </p>
                  </li>
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={purchase} />
                    </div>
                    <p className="item--text">
                    Órdenes de compra en tiendas de software y hardware para gaming.
                    </p>
                  </li>
                </ol>
            </div>
          </div>
        
          <div className="winners--list wow fadeIn" data-wow-offset="200">
            <div className="winners-prize prize-5">
            <h3 className="prize--title">
                  Top 5
                </h3>
                <ol className="item--list">
                 <li className="list--item">
                    <div className="item--icon">
                    <img src={kit} />
                    </div>
                    <p className="item--text">
                    Kit completo de periféricos (incluye monitores competitivos).
                    </p>
                  </li>
                <li className="list--item">
                    <div className="item--icon">
                    <img src={purchase} />
                    </div>
                    <p className="item--text">
                    Órdenes de compra en tiendas de software y hardware para gaming.
                    </p>
                  </li>
                </ol>
            </div>
            <div className="winners-prize prize-10">
            <h3 className="prize--title">
                  Top 10
                </h3>
                <ol className="item--list">
                  <li className="list--item">
                    <div className="item--icon">
                    <img src={purchase} />
                    </div>
                    <p className="item--text">
                    Órdenes de compra en tiendas de software y hardware para gaming.
                    </p>
                  </li>
                </ol>
            </div>
          </div>
        </div>
       </section>
      
       <section ref={this.references.features} className="games--section">
         <div className="wrapper">
         <h2 className="games--title wow fadeIn" data-wow-offset="200">Videojuegos que competirán</h2>
         <ol className="games--list wow fadeIn" data-wow-offset="200">
           <li className="game--item game--1">
              <img src={fortnite} className="game--image"/>
            <div className="game--description">

             <h3 className="game--title">Fortnite</h3>
             <div className="gamers--container">
             <div className="description--item troops--container first blue">
                <div className="item--icon">
                  <img src={players} />
                </div>
                <p className="item--text">
                  100 jugadores
                </p>
             </div>
             <div className="description--item troops--container blue">
                <div className="item--icon">
                <img src={team} />
                </div>
                <p className="item--text">
                50 equipos
                </p>
            </div>
             </div>
             <div className="description--item date--container">
               <div className="item--icon">
               <img src={calendar} />
                </div>
                <p className="item--text">
                <span>Fin de semana 1 </span><br/>Sábado 11 de Diciembre
                </p>
            </div>
            <div className="description--item time--container">
            <div className="item--icon">
            <img src={clock} />
                </div>
                <p className="item--text">
                Desde las 14:00hs
                </p>
            </div>
            <p className="type--text">
              <span>Modalidad:</span> Dúos
            </p>
            </div> 
           </li>
           <li className="game--item game--2">
              <img src={cs} className="game--image"/>
            <div className="game--description">

             <h3 className="game--title">CSGO</h3>
             <div className="gamers--container">
             <div className="description--item troops--container first blue">
                <div className="item--icon">
                  <img src={players} />
                </div>
                <p className="item--text">
                  160 jugadores
                </p>
             </div>
             <div className="description--item troops--container blue">
                <div className="item--icon">
                <img src={team} />
                </div>
                <p className="item--text">
                32 equipos
                </p>
            </div>
             </div>
             <div className="description--item date--container">
               <div className="item--icon">
               <img src={calendar} />
                </div>
                <p className="item--text">
                <span>Fin de semana 1 </span><br/>Domingo 12 de Diciembre
                </p>
            </div>
            <div className="description--item time--container">
            <div className="item--icon">
            <img src={clock} />
                </div>
                <p className="item--text">
                Desde las 14:00hs
                </p>
            </div>
            <p className="type--text">
              <span>Modalidad:</span> Equipos de 5
            </p>
            </div> 
           </li>
           <li className="game--item game--3">
              <img src={cod} className="game--image"/>
            <div className="game--description">

             <h3 className="game--title">Call of Duty</h3>
             <div className="gamers--container">
             <div className="description--item troops--container first blue">
                <div className="item--icon">
                  <img src={players} />
                </div>
                <p className="item--text">
                148 jugadores
                </p>
             </div>
             <div className="description--item troops--container blue">
                <div className="item--icon">
                <img src={team} />
                </div>
                <p className="item--text">
                37 equipos
                </p>
            </div>
             </div>
             <div className="description--item date--container">
               <div className="item--icon">
               <img src={calendar} />
                </div>
                <p className="item--text">
                <span>Fin de semana 2 </span><br/>Sábado 18 de Diciembre
                </p>
            </div>
            <div className="description--item time--container">
            <div className="item--icon">
            <img src={clock} />
                </div>
                <p className="item--text">
                Desde las 14:00hs
                </p>
            </div>
            <p className="type--text">
              <span>Modalidad:</span> Equipos de 4
            </p>
            </div> 
           </li>
           <li className="game--item game--4">
              <img src={lol} className="game--image"/>
            <div className="game--description">

             <h3 className="game--title">League of Legends</h3>
             <div className="gamers--container">
             <div className="description--item troops--container first blue">
                <div className="item--icon">
                  <img src={players} />
                </div>
                <p className="item--text">
                150 jugadores
                </p>
             </div>
             <div className="description--item troops--container blue">
                <div className="item--icon">
                <img src={team} />
                </div>
                <p className="item--text">
                30 equipos
                </p>
            </div>
             </div>
             <div className="description--item date--container">
               <div className="item--icon">
               <img src={calendar} />
                </div>
                <p className="item--text">
                <span>Fin de semana 2 </span><br/>Domingo 19 de Diciembre
                </p>
            </div>
            <div className="description--item time--container">
            <div className="item--icon">
            <img src={clock} />
                </div>
                <p className="item--text">
                Desde las 14:00hs
                </p>
            </div>
            <p className="type--text">
              <span>Modalidad:</span> Equipos de 5
            </p>
            </div> 
           </li>
           
         </ol>
          </div>
      </section>

      <section className="how-to-play--section">
        <div className="wrapper">
          <div className="steps--container wow fadeIn" data-wow-offset="200">
            <h2 className="steps--title">Cómo participar</h2>
            <ul className="steps--list">
              <li className="steps--item">
                <span className="steps--icon">
                  1
                </span>
                <p className="steps--text">
                Creá tu cuenta en TROOP
                </p>
              </li>
              <li className="steps--item">
                <span className="steps--icon">
                  2
                </span>
                <p className="steps--text">
                Descargate la App en tu celular o logueate en la web.
                </p>
              </li>
              <li className="steps--item">
                <span className="steps--icon">
                  3
                </span>
                <p className="steps--text">
                Creá o unite a una Troop ¡Podés invitar a tus amigos o conocer jugadores nuevs!
                </p>
              </li>
              <li className="steps--item">
                <span className="steps--icon">
                  4
                </span>
                <p className="steps--text">
                ¡Anotate en la Troop CUP 21 y preparate para las clasificatorias!
                </p>
              </li>
            </ul>
          </div>
          <div className="information--container wow fadeIn" data-wow-offset="200">
            <h2 className="information--title">Clasificación</h2>
              <p className="information--text">
              Para clasificar en cada juego, una vez registrados los Troops y jugadores, tendrán que empezar a sumar puntos jugando en sus respectivos juegos en las fechas de clasificatorias pautadas. Clasificarán los equipos que logren mejores puntajes de cada juego.
              <br/><br/>
              La etapa de clasificación abrirá el 01/10/21 a las 14:00 hs(zona horaria de Buenos Aires, GMT-3) y cerrará a las 14:00 hs del 15/10/21 (misma zona horaria).
              <br/><br/>
              La etapa clasificatoria no será presencial sino de forma remota. El torneo será exclusivo para personas que residan y jueguen en Argentina.
              <br/><br/>
              Los equipos que clasifiquen recibirán en sus casas la invitación para poder asistir al evento.
              </p>
             </div>
        </div>
      </section>
      <section className="checkout--section">
        <div className="wrapper">
        <div>
              <Link to="/tournaments" className="button--global">Anotate a la Troop CUP 21</Link>
              <p className="anchor">Si todavía no tenés una cuenta <Link to="/signup">Registrate en TROOP</Link></p>
            </div>
        </div>
      </section>
     
     <Footer/>
     </div>
    </div>
    );
  }
}
