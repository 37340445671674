import React from "react"
import logo from '../images/logo_negro.svg';
import menu from '../images/menu.png';
import close from '../images/close.png';
import { Link } from "gatsby"
import wpp from '../images/wpp-mini.svg';
import phone from '../images/phone-mini.svg';

export default class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  openMobileTopbar(){
    console.log("close");
    this.setState({
      open: !this.state.open,
    })
  }

  onMobileClick(ref){
    this.setState({
      open: false,
    })
    this.props.handlerNavigation(ref)
  }

  render(){
    return(
      <header>
        <div className="topbar">
          <div className="wrapper">
            <Link to={'/'} >
              <img src={logo} className="nav--logo" alt="HVM cobranzas"></img>
            </Link>  
            <img src={this.state.open ? close : menu} onClick={() => this.openMobileTopbar()} className={"topbar--menu " + (this.state.open ? 'fixed' : '')}></img>
            <div>
              <div className="links--container">
                <div className="link--container">
                  <Link to='/sobre-troop' className="nav--item">QUÉ ES TROOP</Link>
                </div>
                <div className="link--container">
                  <Link to='/troop-pro' className="nav--item">PRO</Link>
                </div>
                <div className="link--container">
                  <Link to='/preguntas-frecuentes' className="nav--item">FAQ</Link>
                </div>
                <div className="link--container box button--global yellow">
                  <Link to='/troop-cup' className="nav--item">TROOP CUP 21</Link>
                </div>
              </div>
            </div>
            <div className="links--container">
            <div className="link--container box button--global blue">
                  <Link to="/login" className="nav--item">LOGIN</Link>
            </div>
            </div>
            {this.state.open &&
              <div className="links--container--mobile">
                <div className="link--container">
                  <Link to='/' className="nav--item">HOME</Link>
                </div>
                <div className="link--container">
                  <Link to='/sobre-troop' className="nav--item">QUÉ ES TROOP</Link>
                </div>
                <div className="link--container">
                <Link to='/troop-pro' className="nav--item">PRO</Link>
                </div>
                <div className="link--container">
                <Link to='/preguntas-frecuentes' className="nav--item">FAQ</Link>
                </div>
                <div className="link--container">
                <Link to='/troop-cup' className="nav--item">TROOP CUP 21</Link>
                </div>
              </div>
            }
          </div>
        </div>
      </header>
    );
  }
}
