import React from "react"
import {Link} from 'gatsby'
import logo from '../images/logo-type-color.svg'
import twitter from '../images/twitter-icon.svg'
import twitch from '../images/twitch-icon.svg'
import yt from '../images/yt-icon.svg'
import instagram from '../images/instagram-icon.svg'
import map from '../images/map.png'

export default function Footer() {
  return(
    <footer>
      <div className="footer">
        <div className="wrapper">
          <div className="info--container">
          <img src={logo} ></img> 
            <div className="links--container">
              <ul>
                <Link to={'/sobre-troop'}>Qué es Troop</Link>
                <Link to={'/preguntas-frecuentes/'}>FAQ</Link>
                <Link to={'/troop-cup'}>Troop Cup 21</Link>
                <Link to={'/troop-pro'}>Troop Pro</Link>
              </ul>
              <ul>
                <Link to={'/'}>Condiciones</Link>
                <Link to={'/'}>Privacidad</Link>
                <Link to={'/'}>Licencias</Link>
              </ul>
            </div>
            <div className="sm--container">
              <a>
                <img src={instagram} ></img> 
              </a>
              <a>
              <img src={twitter} ></img> 
              </a>
              <a>
              <img src={yt} ></img> 
              </a>
              <a>
              <img src={twitch} ></img> 
              </a>
            </div>
          </div>
          <p>
          Copyright © 2023 | All rights reserved | Troop
          </p>
          {/* */}
        </div>
      </div>
    </footer>
  );
}
